import React, { Component } from 'react';
import Iframe from 'react-iframe'
import { generateURLAffichage } from '../tools/fonctions'
import Filtre from '../components/layout/Filtre'
import { firestoreConnect } from 'react-redux-firebase'
import { subscribe, unsubscribe } from '../tools/eventDispatcher';

class AffichageRapport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            METABASE_KEY: "",
            url: null,
            error: null,
            nomRapport: null, // State to track nomRapport from localStorage
            Secteur: null, // State to track Secteur from localStorage
            Agence: null // State to track Agence from localStorage
        };
    }

    componentDidMount() {
        const { firestore } = this.props
        firestore.collection('metabase')
            .get().then((key) => {
                let metabaseKey = '';
                key.forEach(doc => {
                    metabaseKey = doc.data().key
                })
                this.setState({
                    METABASE_KEY: metabaseKey
                }, () => {
                    // After METABASE_KEY is set, proceed to generate the URL
                    this.generateURL();
                });

            })

        // Subscribe to localStorageChange event
        subscribe('localStorageChange', this.handleStorageChange);
    }

    componentWillUnmount() {
        // Unsubscribe from localStorageChange event
        unsubscribe('localStorageChange', this.handleStorageChange);
    }
    
    handleStorageChange = ({ key, value }) => {
        // Update state based on localStorage change
        if (key === 'localStorage') {
            // Check if relevant keys have changed
            const { nomRapport, Secteur, Agence } = value;
            if (nomRapport !== this.state.nomRapport || Secteur !== this.state.Secteur || Agence !== this.state.Agence) {
                this.setState({
                    nomRapport,
                    Secteur,
                    Agence
                }, () => {
                    // After state is updated, generate the URL
                    this.generateURL();
                });
            }
        }
    }

    generateURL() {
        const { nomRapport, typeRapport, numRapport, Secteur, Agence, dateDeb, dateFin, nomParamSecteur, nomParamAgence, dateParamDeb, dateParamFin } = window.localStorage;

        // Check if METABASE_KEY is available and then generate URL
        if (nomRapport && this.state.METABASE_KEY) {
            generateURLAffichage(typeRapport, numRapport, Secteur, Agence, dateDeb, dateFin, nomParamSecteur, nomParamAgence, dateParamDeb, dateParamFin, this.state.METABASE_KEY)
                .then(url => {
                    this.setState({ url });
                })
                .catch(error => {
                    this.setState({ error });
                });
        }
    }
    render() {
        const { url, error } = this.state;

        return (
            <div className="rapport m-0 bg-white w-100 d-inline-block">
                <Filtre />
                {error && <div className="error">Error: {error.message}</div>}
                {!url && !error && <div className="loading">Loading...</div>}
                {url && <Iframe url={url} position="absolute" width="100%" height="86.8%" top="0" left="0" frameBorder="0" />}
                {!url && !error && !window.localStorage.nomRapport && !this.state.METABASE_KEY && <div className="no-rapport">Veuillez sélectionner un rapport</div>}
            </div>
        );
    }
}

export default firestoreConnect([
    { collection: 'metabase' }
])(AffichageRapport)