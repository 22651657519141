import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { wrongCode, connexion, resetAuthState, expired } from '../store/actions/authActions'
import { titleColor } from '../tools/colors'
import { firestoreConnect } from 'react-redux-firebase'
import { generateUUID, getIp } from '../tools/fonctions';

class Validation extends Component {
    state={
        codeIns: ''
    }

    componentDidMount() {
        this.props.resetAuthState()
        //console.log(this.props.codeVal)
        window.localStorage.setItem('UUID', generateUUID())
        getIp()
        if(!window.localStorage.email)
            this.props.history.push('/authentification')        
    } 

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const todaysTimestamp = + new Date()
        const { firestore } = this.props
        firestore.collection('GUID').where('email','==',window.localStorage.email)
        .get().then((utilisateur) => {
            utilisateur.forEach(doc => {
                if(this.state.codeIns !== doc.data().code)
                    this.props.wrongCode()
                else if(todaysTimestamp > doc.data().lifetime)
                    this.props.expired()
                else {
                    console.log('Code validé')
                    window.localStorage.setItem('validationStatus', 'true');
                    this.props.connexion(window.localStorage.email, window.localStorage.numTel)
                }
            })
        })
    }
    
    render() {
        const { authSuccess, authError } = this.props;
        console.log(this.state, this.props)
        return(
            <div className="validation">
                <div className="container" style={{border:' 1px solid', borderColor: titleColor, position:"relative", top:"50px", backgroundColor:"white"}}>
                    <form onSubmit={this.handleSubmit}>
                        <h1 style={{color:titleColor}}>Code de validation</h1>
                        <div className="form-group">  
                            <label htmlFor="code" className="h4">Code</label>
                            <input type="text" className="form-control" id='codeIns' onChange={this.handleChange} /> 
                        </div>
                        <div className="form-group">
                            <Link to='/authentification' className="btn btn-danger">Retour</Link>
                            <button className="btn btn-primary ml-1">Valider</button>
                            <div className="text-success">
                                {authSuccess ? <p>{authSuccess}</p> : null}
                            </div>
                            <div className="text-danger">
                                {authError ? <p>{authError}</p> : null}
                            </div>
                            <div className="text">
                                {authError || authSuccess ? null : <em>Un code de validation vous a été envoyé par mail, cela peut prendre quelques minutes...</em>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        authSuccess: state.auth.authSuccess,
        authError: state.auth.authError,
        isVal: state.auth.isVal,
        codeVal: state.auth.code
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        connexion: (email, numTel) => dispatch(connexion(email, numTel)),
        wrongCode: () => dispatch(wrongCode()),
        resetAuthState: () => dispatch(resetAuthState()),
        expired: () => dispatch(expired())
    }
}

export default withRouter(compose(connect(mapStateToProps, mapDispatchToProps),firestoreConnect([{ collection: 'GUID'}]))(Validation));