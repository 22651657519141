import React, {Component} from 'react';
import Authentification from './screens/Authentification'
import AffichageRapport from './screens/AffichageRapport'
import Navbar from './components/layout/Navbar'
import Validation from './screens/Validation'
import MenuRapport from './screens/MenuRapport'
import { Switch, Route,withRouter } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { aucunDroit, isAuthentified } from './store/actions/authActions'
import firebase from 'firebase/app'


class App extends Component {
  state = {
    droit: ''
  }
  componentDidMount() {
    firebase.auth().signInAnonymously().catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
    if(typeof window.localStorage.UUID === 'undefined') {
      console.log("Pas d'uuid")
      this.props.history.push('/authentification')
    }
    else { 
      this.getDroit()
      this.verifUtilisateur();
    }
    
  }

  getDroit = () => {
    if(typeof window.localStorage.email === "undefined") {
      console.log("Pas d'email à récupérer")
      this.props.history.push('/authentification')
    } 
    else {
      const { firestore } = this.props
      firestore.collection('autorisations').where('utilisateur','==',window.localStorage.email)
      .get().then((utilisateur) => {
        utilisateur.forEach(doc => {
          this.setState({
              droit: doc.data().autorisation
          })
        })
      })
    }
  }

  verifUtilisateur = () => {
    const { firestore } = this.props;
    firestore.collection('utilisateurs').where('UUID','==',window.localStorage.UUID)
    .get().then((listeutilisateur) => {
      console.log(listeutilisateur)
      if(!listeutilisateur.empty) {
        console.log('Utilisateur trouvé')
        if(!(this.state.droit === 'National' || this.state.droit === 'Secteur' || this.state.droit === 'Agence')) {
          this.props.aucunDroit();
          this.props.history.push('/authentification');
        }
        else {
          if(window.localStorage.getItem('validationStatus') !== 'true') {
            this.props.history.push('/authentification');
          } 
          else {
            this.props.isAuthentified(true)
            this.props.history.push('/menu-rapport')
          }
        }
      }
      else {
        console.log("Pas d'utilisateur")
        this.props.history.push('/authentification')
      }
    }) 
  } 
       
  render() {
    return (
      <div className="App">
        <Navbar />
        <Switch>
          <Route path='/authentification' component={Authentification}/>
          <Route path='/rapport' component={AffichageRapport}/>
          <Route path='/validation' component={Validation}/>
          <Route path='/menu-rapport' component={MenuRapport}/>
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    aucunDroit: () => dispatch(aucunDroit()),
    isAuthentified: (auth) => dispatch(isAuthentified(auth)) 
  }
}

export default withRouter(compose(connect(null, mapDispatchToProps),
firestoreConnect([
  { collection: 'utilisateurs'}
]))(App))
