import firebase from 'firebase/app'
import 'firebase/firestore'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBfU9Tdx_j8HI7YDXKKNSeczyYbr3EEoos",
    authDomain: "bi-secu.firebaseapp.com",
    databaseURL: "https://bi-secu.firebaseio.com",
    projectId: "bi-secu",
    storageBucket: "bi-secu.appspot.com",
    messagingSenderId: "300823451153",
    appId: "1:300823451153:web:308835147d4038ec7743b0"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();

  export default firebase;