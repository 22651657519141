/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { wrongEmail, wrongNumber, resetAuthState, stateCodeToFirestore, isAuthentified } from '../store/actions/authActions'
import { isMobile } from 'react-device-detect'
import './css/Authentification.css'
import { withRouter } from 'react-router-dom'
import { titleColor } from '../tools/colors'
var regexDomain = new RegExp(/^\"?[\w-_\.]*\"?@orencash\.fr$/); 

class Authentification extends Component {
  state = {
    email: '',
    numTel: ''
  }

  componentDidMount() {
    this.props.resetAuthState()
    this.props.isAuthentified(false)
  }
  
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value 
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if(regexDomain.test(this.state.email)) {
      if(isMobile) {
        if(this.state.numTel.length === 10 && this.state.numTel[0] === '0' && (this.state.numTel[1] === '6' || this.state.numTel[1] === '7')) {
          this.submit()
        }
        else {
          this.props.wrongNumber()
        }
      }
      else {
        this.submit()
      }
    }
    else  
      this.props.wrongEmail()
  }

  submit = () => {
    this.sendMail(this.state.email)
    window.localStorage.setItem('email',this.state.email)
    window.localStorage.setItem('numTel',this.state.numTel)
    window.localStorage.setItem('validationStatus', 'false')
    this.props.history.push('/validation')
  }

  sendMail = (email) => {
    fetch('https://us-central1-bi-secu.cloudfunctions.net/sendMail?dest='+email)
    .then((res) => {
        return res.json()
    })
    .then((res) => {
        this.props.stateCodeToFirestore(email, res.code)
    })
}

  render() {
    const { authError } = this.props;
    return (
      <div className="container" style={{border:' 1px solid', borderColor: titleColor, position:"relative", top:"50px", backgroundColor:"white"}}>
        <form onSubmit={this.handleSubmit}>
            <h1 style={{color:titleColor}}>Authentification</h1>
            <div className="form-group">  
                <label htmlFor="email" className="h4">Email</label>
                <input type="email" className="form-control" id='email' placeholder='@orencash.fr' onChange={this.handleChange} /> 
            </div>
            { 
              isMobile ?  
              <div className="mobile"> 
                <div className="form-group"> 
                  <label htmlFor="numTel" className="h4">Numéro de Téléphone</label> 
                  <input type="number" className="form-control" id='numTel' placeholder='06 XX XX XX XX' onChange={this.handleChange} /> 
                </div> 
              </div> 
              : 
                null 
            } 
            <div className="form-group">
                <button className="btn btn-primary">Valider</button>
                <div className="text-danger">
                    {authError ? <p>{authError}</p> : null}
                </div>
            </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    wrongEmail: () => dispatch(wrongEmail()),
    wrongNumber: () => dispatch(wrongNumber()),
    resetAuthState: () => dispatch(resetAuthState()),
    stateCodeToFirestore: (email, code) => dispatch(stateCodeToFirestore(email, code)),
    isAuthentified: (auth) => dispatch(isAuthentified(auth))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authentification))