const initState = {
    authSuccess: null,
    authError: null,
    email: '',
    numTel: '',
    code: '',
    auth: false
}

const authReducer = (state = initState, action) => {
    switch(action.type) {
        case 'ERREUR_CONNEXION':
            console.log('Connexion échoué');
            return {
                ...state,
                authSuccess: null,
                authError: 'Connexion échoué'
            }
        case 'CONNEXION_REUSSI':
            console.log('Connexion réussi');
            return {
                ...state,
                authSuccess: 'Connexion réussi',
                authError: null,
            }
        case 'MAUVAISE_INFO_EMAIL':
            console.log('Mauvaise info utilisateur');
            return {
                ...state,
                authSuccess: null,
                authError: "Erreur dans la saisie de l'email"
            }
        case 'MAUVAISE_INFO_NUM':
            console.log('Mauvaise info utilisateur');
            return {
                ...state,
                authSuccess: null,
                authError: "Erreur dans la saisie du numéro de téléphone"
            }
        case 'AUCUN_DROIT':
            console.log('Aucun droit');
            return {
                ...state,
                authSuccess: null,
                authError: "Vous n'avez aucun droit"
            }
        case 'MAUVAIS_CODE':
            console.log('Mauvais code de validation')
            return {
                ...state,
                authSuccess: null,
                authError: "Mauvais code de validation, veuillez réessayer"
            }
        case 'RESET_AUTH_STATE':
            console.log('Auth State Reset')
            return {
                ...state,
                authSuccess: null,
                authError: null,
            }
        case 'STATE_CODE_TO_FIRESTORE':
            console.log('Code : '+action.code)
            console.log('Ajouté à Firestore')
            return {
                ...state,
                code: action.code
            }
        case 'CODE_EXPIRE':
            console.log('Code expiré')
            return {
                ...state,
                authSuccess: null,
                authError: "Code expiré, veuillez retourner à la page précédente et resaisir votre adresse email"
            }
        case 'AUTH_STATUS': 
            console.log('auth', action.auth)
            return {
                ...state,
                auth: action.auth
            }
        default:
            return state;
    }
};

export default authReducer;