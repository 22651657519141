const listeners = {};

export function subscribe(eventType, listener) {
    if (!listeners[eventType]) {
        listeners[eventType] = [];
    }
    listeners[eventType].push(listener);
}

export function unsubscribe(eventType, listener) {
    if (listeners[eventType]) {
        listeners[eventType] = listeners[eventType].filter(l => l !== listener);
    }
}

export function emit(eventType, data) {
    if (listeners[eventType]) {
        listeners[eventType].forEach(listener => listener(data));
    }
}