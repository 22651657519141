export const connexion = (email, numTel) => {                  //Cette fonction va ajouter dans la collection utilisateurs toutes les données de l'utilisateur ainsi que son système dans Firestore
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('utilisateurs').add({
            email: email,
            numTel: numTel,
            ip: window.localStorage.ip,
            UUID: window.localStorage.UUID,
            dateCreation: new Date()
        })
        .then(() => {
            window.localStorage.removeItem('ip')
            dispatch({type: 'CONNEXION_REUSSI'})
            window.location.assign('/menu-rapport')
        })
        .catch((err) => {
            dispatch({type: 'ERREUR_CONNEXION', err})
        })
    }
}

export const stateCodeToFirestore = (email, code) => {                 //Cette fonction ajoute dans la collection GUID l'email de l'utilisateur, le code à saisir et le lifetime du code
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();
        const date = new Date()
        const lifetime = date.setDate(date.getDate() + 1)
        firestore.collection('GUID').where('email','==',window.localStorage.email)
        .get().then((utilisateur) => {
            if(!utilisateur.empty)
                utilisateur.forEach(doc => {
                    console.log('doc ID : '+doc.id)
                    if(doc.id)
                        firestore.collection('GUID').doc(doc.id).set({
                            email: email,
                            lifetime: lifetime,
                            code: code
                        })
                })
            else
                firestore.collection('GUID').add({
                    email: email,
                    lifetime: lifetime,
                    code: code
                })
        })
        dispatch({type: 'STATE_CODE_TO_FIRESTORE'})
    }
}

export const isAuthentified = (auth) => {
    return (dispatch, getState) => {
        dispatch({type: 'AUTH_STATUS', auth: auth})
    }
}

export const wrongCode = () => {
    return (dispatch, getState) => {
        dispatch({type: 'MAUVAIS_CODE'})
    }
}

export const expired = () => {
    return (dispatch, getState) => {
        console.log(getState)
        dispatch({type: 'CODE_EXPIRE'})
    }
}
export const wrongEmail = () =>  {
    return (dispatch, getState) => {
        dispatch({type: 'MAUVAISE_INFO_EMAIL'})
    }
}

export const wrongNumber = () => {
    return (dispatch, getState) => {
        dispatch({type: 'MAUVAISE_INFO_NUM'})
    }
}

export const aucunDroit = () =>  {
    return (dispatch, getState) => {
        dispatch({type: 'AUCUN_DROIT'})
    }
}

export const resetAuthState = () => {
    return (dispatch, getState) => {
        dispatch({type: 'RESET_AUTH_STATE'})
    }
}