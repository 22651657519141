import React, {Component} from 'react'
import { getPrenom } from '../../tools/fonctions'
import { firestoreConnect } from 'react-redux-firebase'
import { isMobile } from 'react-device-detect'
import { navbarColor } from '../../tools/colors'
import { compose } from 'redux'
import { connect } from 'react-redux'

class Navbar extends Component {
    state={
        rapport: '',
        autorisation: '',
    }
    componentDidMount() {
        console.log(window.location.pathname)
        if(window.localStorage.email) {
            const { firestore } = this.props
            firestore.collection('autorisations').where('utilisateur','==',window.localStorage.email)              //Récupération des info de l'utilisateur via son email
            .get().then((utilisateur) => {
                utilisateur.forEach(doc => {
                    this.setState({
                        autorisation: doc.data().autorisation,
                        nomSecteur: doc.data().Secteur,
                        nomAgence: doc.data().Agence
                    })
                    window.localStorage.setItem('Autorisation',doc.data().autorisation)
                    if(window.localStorage.Autorisation === ' National') {
                        if(window.localStorage.Secteur)
                            window.localStorage.removeItem('Secteur')
                        if(window.localStorage.Agence)
                            window.localStorage.removeItem('Agence')
                    }
                    if(window.localStorage.Autorisation === 'Secteur') {
                        window.localStorage.setItem('Secteur', doc.data().Secteur)
                        if(window.localStorage.Agence)
                            window.localStorage.removeItem('Agence')
                    }
                    else if(window.localStorage.Autorisation === 'Agence') {
                        window.localStorage.setItem('Secteur', doc.data().Secteur)
                        window.localStorage.setItem('Agence', doc.data().Agence)
                    }
                        
                })
            })
        }
    }

    render() {
        const {nomSecteur, nomAgence} = this.state
        const { isAuth } = this.props
        console.log('isAuth', isAuth)
        return(
            <nav className="navbar navbar-expand-sm sticky-top" style={{display:"block", backgroundColor:navbarColor}}> 
                <div className="d-flex"> 
                    {
                        <a href='/' className="navbar-text mr-auto" style={{fontSize:"20px", color: "white"}}>
                        Bonjour {isAuth ? getPrenom() : null}
                            , {isMobile ? 
                                <p className='mb-0'>
                                { 
                                    !this.state.autorisation || !isAuth ? 
                                        null
                                    :
                                        this.state.autorisation === 'National' ?
                                            this.state.autorisation
                                        :
                                            this.state.autorisation === 'Secteur' ? 
                                                this.state.autorisation+' '+nomSecteur
                                            :
                                                this.state.autorisation+' '+nomAgence
                                }
                                </p>
                                : 
                                    !this.state.autorisation || !isAuth ? 
                                            null
                                        :
                                            this.state.autorisation === 'National' ?
                                                this.state.autorisation
                                            :
                                                this.state.autorisation === 'Secteur' ? 
                                                    this.state.autorisation+' '+nomSecteur
                                                :
                                                    this.state.autorisation+' '+nomAgence
                            } 
                        </a>
                        }
                <a href='https://www.orencash.fr/' className="navbar-brand">
                    <img src={require('../img/logo-OEC.png')} alt='Logo OEC' style={{width:"100px", position:"absolute",top:"10px", right: "10px"}} />
                </a>
                </div>
            </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuth: state.auth.auth 
    }
}

export default compose(connect(mapStateToProps),firestoreConnect([{collection: 'autorisations'}]))(Navbar)