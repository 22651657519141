import React, { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import 'moment/locale/fr'
import './css/Filtre.css'
import { firestoreConnect } from 'react-redux-firebase'
import { Link, Redirect } from 'react-router-dom';
import { storeRapport } from '../../tools/fonctions';
import { isMobile } from 'react-device-detect';

class Filtre extends Component {
    state = {
        agences: [],
        secteurs: [], 
        dateDeb: new Date(),
        dateFin: new Date(),
        nomSect: "",
        nomAg: ""
    }
    componentDidMount() {
        
        if(!window.localStorage.email)
            return ( <Redirect to='/authentification'/> )
        else {
            fetch('https://us-central1-bi-secu.cloudfunctions.net/getListeAgences') 
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                response.map((agence) => {
                    //console.log(agence)
                    this.setState({
                        agences: [...this.state.agences, agence] 
                    })
                    return agence
                })
            })

            fetch('https://us-central1-bi-secu.cloudfunctions.net/getListeSecteurs') 
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                let secteurs = response.map((secteur) => {
                    return secteur
                })
                this.setState({
                    secteurs: secteurs,  
                }) 
            })
                //console.log(agences)
            this.setState({                          
                nomSect: window.localStorage.Secteur,
                nomAg: window.localStorage.Agence
            }) 
        }
        
    }

    handleClickReinit = (e) => {
        console.log('e.target.id', e.target.id)
        switch(window.localStorage.Autorisation) {                  //Pour certain droit on empêche la réinitialisation (ex: responsable secteur ne peut pas réinitialiser son secteur)
            case'National':
                this.setState({
                    [e.target.id]: ''
                })
            case'Secteur':
                if(e.target.id !== 'nomSect')
                    this.setState({
                        [e.target.id]: ''
                    })
            default:
                break;
        }
        if(e.target.id === 'dateDeb')                               //Réinitialisation à la date d'aujourd'hui
            this.setState({
                dateDeb: new Date(),
            })
        if(e.target.id === 'dateFin')
            this.setState({
                dateFin: new Date(),
            })
    }

    handleChange = (e) => {
        // console.log(e.target.id)
        // console.log(e.target.value)
        this.setState({
            [e.target.id]: e.target.value
        })

        if(e.target.id === "nomSect") {
            this.setState({
                nomAg: ""
            })
        }
    }

    handleDayChangeDeb = (day) => {
        console.log('day', day)
        this.setState({
            dateDeb: day
        })
    }

    handleDayChangeFin = (day) => {
        this.setState({
            dateFin: day
        })
    }

    render() {
        const { secteurs, agences, nomSect, nomAg, dateDeb, dateFin } = this.state
        const { Autorisation, nomParamSecteur, nomParamAgence, dateParamDeb, dateParamFin } = window.localStorage
        //console.log('secteurs', secteurs.sort((a,b) =>  a.nomSecteur > b.nomSecteur ? 1 : b.nomSecteur > a.nomSecteur ? -1 : 0))
        var sortedSecteurs = secteurs.sort((a,b) =>  a.nomSecteur > b.nomSecteur ? 1 : b.nomSecteur > a.nomSecteur ? -1 : 0)
        var sortedAgences = agences.sort((a,b) =>  a.nomAgence > b.nomAgence ? 1 : b.nomAgence > a.nomAgence ? -1 : 0)

        const optionSecteurDeroulant = sortedSecteurs.map((secteur, i) => {
            if(secteur.isActif) {
                return(
                    <option key={i} value={secteur.nomSecteur}>{secteur.nomSecteur}</option>
                )
            }
        })
        const optionAgenceDeroulant = sortedAgences.map((agence, i) => {
            if(agence.isActive) {
                if(nomSect !== "") {
                    var filteredSecteur = secteurs.filter(sect => sect.nomSecteur === nomSect)
                    // console.log(filteredSecteur)
                    if(agence.idSecteur === filteredSecteur[0].idSecteur) {
                        return(
                            <option key={i} value={agence.nomAgence.replace('Agence de ', '')}>{agence.nomAgence.replace('Agence de ', '')}</option>
                        )
                    }
                }
                else {
                    return(
                        <option key={i} value={agence.nomAgence.replace('Agence de ', '')}>{agence.nomAgence.replace('Agence de ', '')}</option>
                    )
                } 
            }
        })
        return(
            <div className="container">
            <div className="filtre d-flex">
                <form className="d-inline-block mt-2">
                    <div className={isMobile ? "col" : "row"}>
                        {
                            nomParamSecteur ? 
                                <div className="form-group">
                                    <div className="d-flex secteur ml-3" style={{width: '170px'}}>
                                        <select onChange={this.handleChange} value={nomSect} id='nomSect' className='custom-select' disabled={Autorisation === "Secteur" ?  true : false}>
                                            <option value="">{secteurs.length !== 0 ? 'Secteur' : 'Chargement des secteurs'}</option>
                                            {optionSecteurDeroulant}
                                        </select>
                                        <button type="button" className="close" aria-label="Close" onClick={this.handleClickReinit}>
                                            <span id="nomSect" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            :
                                null
                        }
                        {
                            nomParamAgence ? 
                                <div className="form-group">
                                    <div className="d-flex agence ml-3" style={{width: '170px'}}>
                                        <select onChange={this.handleChange} value={nomAg} id='nomAg' className='custom-select' disabled={Autorisation === "Agence" ? true : false}>
                                            <option value="">{agences.length !==0 ? 'Agence' : 'Chargement des agences'}</option>
                                            {optionAgenceDeroulant}
                                        </select>
                                        <button type="button" className="close" aria-label="Close" onClick={this.handleClickReinit}>
                                            <span id="nomAg" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            :
                                null
                        }
                        {
                            dateParamDeb ?
                                <div className="form-group">
                                    <div className="date-debut ml-3">
                                        <div className="d-flex input-group" id="deb-datepicker">
                                            <DayPickerInput 
                                                formatDate={formatDate}
                                                parseDate={parseDate}
                                                value={this.state.dateDeb}
                                                format="L"
                                                onDayChange={this.handleDayChangeDeb}
                                                placeholder="Date de début"
                                                clickUnselectsDay={true}
                                                dayPickerProps={{
                                                    locale: 'fr',
                                                    localeUtils: MomentLocaleUtils,
                                                }}
                                            />
                                            <button type="button" className="close" aria-label="Close" onClick={this.handleClickReinit}>
                                                <span id="dateDeb" aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                        }
                        {
                            dateParamFin ? 
                                <div className="form-group">
                                    <div className="date-fin ml-3">
                                        <div className="d-flex input-group" id="fin-datepicker">
                                            <DayPickerInput 
                                                formatDate={formatDate}
                                                parseDate={parseDate}
                                                value={this.state.dateFin}
                                                format="L"
                                                onDayChange={this.handleDayChangeFin}
                                                placeholder="Date de fin"
                                                clickUnselectsDay={true}
                                                dayPickerProps={{
                                                    locale: 'fr',
                                                    localeUtils: MomentLocaleUtils,
                                                }}
                                            />
                                            <button type="button" className="close" aria-label="Close" onClick={this.handleClickReinit}>
                                                <span id="dateFin" aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                        }
                        {
                            nomParamSecteur || nomParamAgence || dateParamDeb || dateParamFin ?
                                <div className="form-group">
                                    <div className='bouton ml-3'>
                                        <div className="bouton validation" id="validation">                      
                                            <Link to={{
                                                pathname: '/rapport',
                                            }}
                                            onClick={() => {storeRapport(null, null, null, nomSect, nomAg, dateDeb, dateFin)}}
                                            className="btn btn-primary" 
                                            style={{width: '100px'}}>
                                                Chercher
                                            </Link> 
                                        </div>
                                    </div>
                                </div>
                            
                            :
                                <div className="form-group">
                                    <h5 className='mt-1'>Aucun filtre</h5>
                                </div>
                        }
                    </div>           
                </form>
            </div>
            </div>
        )
    }
} 

export default firestoreConnect([{collection: 'autorisations'}])(Filtre)