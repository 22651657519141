import React, { Component } from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import { withRouter, Link } from 'react-router-dom'
import { storeRapport } from '../tools/fonctions'
import { titleColor, orGradient } from '../tools/colors'

class MenuRapport extends Component {
    state = {
        rapport: [],
    }
    componentDidMount() {
        if(!window.localStorage.email) 
            this.props.history.push('/authentification')
        else {
            window.localStorage.removeItem('nomRapport')                                        
            window.localStorage.removeItem('typeRapport')
            window.localStorage.removeItem('numRapport')
            if(window.localStorage.Autorisation === 'National') {                               //On peut éviter de remove les item agences et secteurs pour sauvegarder le filtre mais cela peut peut-être poser problème pour le droit national
                window.localStorage.removeItem('Secteur')
                window.localStorage.removeItem('Agence')
            }
            if(window.localStorage.Autorisation === 'Secteur')
                window.localStorage.removeItem('Agence')
            window.localStorage.removeItem('dateDeb')
            window.localStorage.removeItem('dateFin')
            window.localStorage.removeItem('nomParamSecteur')
            window.localStorage.removeItem('nomParamAgence')
            window.localStorage.removeItem('dateParamFin')
            window.localStorage.removeItem('dateParamDeb')
            const { firestore } = this.props
            firestore.collection('GUID').where('email','==',window.localStorage.email)              //Récupération des info de l'utilisateur via son email
            .get().then((utilisateur) => {
                utilisateur.forEach(doc => {
                    console.log(doc.id)
                    firestore.collection('GUID').doc(doc.id).delete()                           //Lorsqu'on arrive sur la page, on supprime le doc concernant le code de verification de la base
                })
            })
            firestore.collection('autorisations').where('utilisateur','==',window.localStorage.email)
            .get().then((utilisateur) => {
            utilisateur.forEach(doc => {
                this.setState({
                    rapport: doc.data().Rapports
                })
                })
            })
        }
    }

    render() {
        return(
            <div className="container menu-rapport bg-white mt-4" style={{border: "solid", borderWidth: "1px", borderColor: titleColor}}>
                <h1 style={{color: titleColor}}>Rapports</h1>
                <div className="d-flex flex-column">
                    <div className="card-columns">
                    {this.state.rapport.map((rapport) => {
                        return (
                            <div className="card mb-2" style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.19)'}} key={rapport.nomRapport}>
                                <div className="card-body" style={orGradient}>
                                    <Link to={{
                                        pathname: '/rapport'
                                    }}
                                    className="h5" 
                                    id={rapport.numRapport} 
                                    name={rapport.typeRapport}
                                    style={{color: 'white'}}
                                    onClick={() => {storeRapport(rapport.nomRapport, rapport.typeRapport, rapport.numRapport, window.localStorage.Secteur, window.localStorage.Agence, null, null, rapport.nomParamSecteur, rapport.nomParamAgence, rapport.nomParamDateDeb, rapport.nomParamDateFin)}}>
                                        {rapport.nomRapport}
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                    </div>
                    
                    
                </div>
            </div>
        )
    }
}

export default withRouter(
    firestoreConnect([
      { collection: 'autorisations', collection: 'GUID'}
    ])(MenuRapport))